import logo from './logo.svg';
import './App.css';
import grip from './caroline.jpg';
import sbf from './sbj 1.jpg';
import down from './down-arrow.svg';

function App() {
  return (
    <div className="App">
      <header className="App-header">

        <div id='bro'>
          <p id='title'>
          grippy
          </p>
          <p id='stuff'>
          would u give it all up for the grippy?
          </p>
          <img src={grip} className="App-logo" alt="logo" />
        <img src={sbf} className="sbf" alt="logo" />
        </div>

      

        <div id='divider'>
        <p id='stuff'>
        one man’s desire for the grippy 
        </p>
        <p id='stuff'>
        nearly killed crypto
        </p>
        <p id='stuff'>
        but today we will become whole again in one trade
        </p>
        <p id='stuff'>the grippy trade.</p>
        <a href="https://t.me/Grippy_Coin">join the grippy movement</a>
        </div>
      </header>
    </div>
  );
}

export default App;
